.plans_container {
    background-color: #fafaff;
    padding: 50px 80px;
  }
  .plans_card {
    background: #ffffff;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    cursor: pointer;
  }
  .plans_heading {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .plans_title, .launch_title{
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
  .plans_p {
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }
  .plans_card_heading {
    text-align: center;
  }
  .plans_card_title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .plans_card_p {
    font-size: 16px;
    display: inline-block;
    margin-top: 5px;
  }
  .hr{
      width: 50%;
      margin: 10px auto;
  }
  .plans_list{
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 150px;
  }
  .plans_list_item{
      display: flex;
  }
  .plans_price{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .plans_price span{
      font-weight: bold;
      /* font-size: 26px; */
      display: inline-block;
      margin: 0;
  }
  .plans_button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .plans_image {
    display: flex;
    justify-content: center;
  }
  .plans_link {
    height: 100%;
    color: #757580;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .plans_link_active {
    height: 100%;
    color: #5152a6;
    border-bottom: 4px solid #5152a6;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .plans_nav {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  .plan_card{
      background-color: #ededff;
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 18px;
      font-weight: 700;
  }
  .plans_banner{
      display: inline-block;
      background-color: #5152a6;
      color: #fff;
      padding: 5px 10px 5px 10px;
      margin-bottom: 10px;
      font-weight: 500;
  }
  .launch_btn{
      margin-top: 30px;
      display: flex;
      justify-content: center;
  }
  .btn_large{
      font-weight: 700;
  }
  .lending_container{
    background-color: #FAFAFF;
}
.lending_card{
    background: #FFFFFF;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
}
  .lending_card_heading{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .lending_title{
    font-size: 32px;
    line-height: 34px;
    text-align: center;
}
.lending_p{
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    margin: 20px 0;
}
.lending_card_title{
    font-size: 24px;
    line-height: 30px;
}
.lending_card_p{
    margin-top: 20px;
    font-size: 16px;
}

.lending_btn{
    background-color: #5152a6;
    color: #fff;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
.lending_image{
    display: flex;
    justify-content: center;
}
.lending_link{
    height: 100%;
    color: #757580;
    padding-bottom: 10px;
    font-size: 14px;
    margin-right: 20px;
}
.lending_link_active{
    height: 100%;
    color: #5152A6;
    border-bottom: 4px solid #5152A6;
    padding-bottom: 10px;
    font-size: 14px;
    margin-right: 20px;
}
.lending_nav{
    width: 100%;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .plans_heading{
        width: 80%;
      }
      .plans_title, .launch_title {
        font-size: 32px;
        line-height: 34px;
      }
      .launch_title{
          width: 80%;
          margin: 0 auto;
      }
      .plans_p {
        font-size: 18px;
        line-height: 30px;
      }
      .plans_card{
          transition: all 0.2s;
      }
      .plans_card:hover{
        transform: scale(1.1);
        position: absolute;
        z-index: 99;
      }
      .popup{
        transform: scale(1.1);
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 99;
        border: 1px solid #5152a6;
      }
}
@media (min-width: 1024px) {
    .plans_title , .launch_title{
        font-size: 48px;
        line-height: 50px;
      }
      .plans_heading {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      .plans_p {
        font-size: 22px;
        line-height: 30px;
        width: 100%;
        margin: 20px 0;
        text-align: center;
      }
      .plans_card_heading {
        height: 120px;
      }
      .plans_card_title {
        font-size: 24px;
        line-height: 30px;
      }
      .plans_card_p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .btn_large{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 30px;
      }
      .lending_title{
        font-size: 32px;
        line-height: 38px;
        text-align: left;
    }
    .lending_p{
        font-size: 20px;
        line-height: 34px;
        width: 80%;
        margin-bottom: 0;
    }
    .lending_card_title{
        line-height: 34px;
    }
    .lending_card_p{
        margin-top: 20px;
        font-size: 16px;
    }
    .lending_heading{
        display: flex;
        justify-content: space-between;
    }
    .lending_link{
        font-size: 18px;
    }
    .lending_link_active{
        font-size: 18px;
    }
}