@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap");

.note_item {
  font-family: "Kalam", cursive;
}

.notes {
  color: #000;
  background: #ffc;
  width: 300px;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.5);
  position: relative;
}

.notes:nth-child(2n) {
  background: #cfc;
}
.notes:nth-child(3n) {
  background: #ccf;
}
.notes:nth-child(4n) {
  background: #ff7eb9;
}
.notes:nth-child(5n) {
  background: #7afcff;
}
