@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cera Pro Light";
  src: url("../fonts/CeraPro-Light.eot");
  src: url("../fonts/CeraPro-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CeraPro-Light.woff2") format("woff2"),
    url("../fonts/CeraPro-Light.woff") format("woff"),
    url("../fonts/CeraPro-Light.ttf") format("truetype"),
    url("../fonts/CeraPro-Light.svg#CeraPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera Pro Medium";
  src: url("../fonts/CeraPro-Medium.eot");
  src: url("../fonts/CeraPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CeraPro-Medium.woff2") format("woff2"),
    url("../fonts/CeraPro-Medium.woff") format("woff"),
    url("../fonts/CeraPro-Medium.ttf") format("truetype"),
    url("../fonts/CeraPro-Medium.svg#CeraPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera Pro Bold";
  src: url("../fonts/CeraPro-Bold.eot");
  src: url("../fonts/CeraPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CeraPro-Bold.woff2") format("woff2"),
    url("../fonts/CeraPro-Bold.woff") format("woff"),
    url("../fonts/CeraPro-Bold.ttf") format("truetype"),
    url("../fonts/CeraPro-Bold.svg#CeraPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body,
html {
  background: #f7f7f9 !important;
  overflow: auto;
}

.scrollbar {
  overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar {
  width: 0;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* Tailwind override to allow Ant Design buttons and icons fit well */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: initial !important;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 240px;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-shadow: inset -1px 0px 0px #d9e1ec;
}

label {
  color: #9595ac !important;
}

.match {
  background-color: #5152a6;
  background-size: 100%;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Cera Pro Light" !important;
}

tspan {
  font-family: "Cera Pro Medium" !important;
  font-size: 8px !important;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 5px 0 !important;
}

.ant-checkbox-inner {
  border-radius: 3px !important;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.my-10 {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-spin-blur {
  opacity: 0 !important;
}

.PhoneInputInput {
  height: 30px;
  outline: none;
}

.PhoneInput {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #84818a;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  transition: all 0.3s;
}

.PhoneInput:focus-within {
  border-color: var(--primary-hover-color);
  box-shadow: 0 0 0 2px var(--primary-shadow-color);
}
